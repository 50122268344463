import React from 'react';
import Layout from '../components/layout';
import queryString from 'query-string';

class Search extends React.Component {
  constructor (props) {
    super(props);
  }

  loadWidget () {
    const client = new window.DatoCmsSearch('6e4087d5a1df8df0903583201958ac', '17076');
    const query = queryString.parse(this.props.location.search);
    client.addWidget(
      '#search-container',
      {
        perPage: 50,              // specify the number of results per page
        initialQuery: query.q   // start the widget with this search
      }
    );
  }

  componentDidMount () {
    if (!document.querySelector('link[href*="datocms-search@0.1.9"]')) {
      const headElement = document.getElementsByTagName('head')[0];
      const self = this;

      const searchCSS = document.createElement('link');
      searchCSS.href = `https://unpkg.com/datocms-search@0.1.9/styles/index.css`;
      searchCSS.rel = 'stylesheet';
      headElement.appendChild(searchCSS);

      const searchScript = document.createElement('script');
      searchScript.src = `https://unpkg.com/datocms-search@0.1.9/dist/datocms-search.widget.js`;
      searchScript.type = 'text/javascript';
      searchScript.setAttribute('data-domain-script', 'db57f6d6-302a-4db6-9f29-1f0b4bf8b1e1');
      searchScript.onload = () => {
        self.loadWidget();
      };
      headElement.appendChild(searchScript);
    } else {
      this.loadWidget();
    }
  }


  render () {
    return (
      <Layout>
        <main className="mx-24 pt-2">
          <div id="search-container"></div>
        </main>
      </Layout>
    );
  }
}

export default Search;
